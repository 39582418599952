import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    strong {
        margin-right: 10px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        border-right: 1px solid ${COLORS.opacify(COLORS.blueDarker, 0.3)};
    }
`;
