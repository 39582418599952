import React from 'react';

import * as S from './styled';

type Props = {
    code: number;
    label: string;
};

const Error = ({ code, label }: Props): JSX.Element => (
    <S.Container>
        <strong>Erreur {code}</strong> {label}
    </S.Container>
);

export default Error;
